<template>
    <v-card
        class="mx-auto"
        v-if="Object.keys(item).length"
    >
        <v-card-title>{{ trans(item.label) }}</v-card-title>
        <v-card-text class="py-2" v-if="item.prefix || typeof item.value != 'undefined' || item.suffix">
            <v-row>
                <v-col class="py-0" cols="4" v-if="item.prefix && model.fields[itemName].prefix">
                    <v-text-field
                        dense
                        v-model="model.fields[itemName].prefix.value"
                        :label="trans(item.prefix.label)"
                    ></v-text-field>
                </v-col>
                <v-col
                    class="py-0"
                    :cols="4 + (!item.prefix ? 4 : 0) + (!item.suffix ? 4 : 0)"
                    v-if="typeof item.value != 'undefined' && model.fields[itemName]"
                >
                    <v-text-field
                        v-model="model.fields[itemName].value"
                        :label="trans('fields.common.value')"
                        dense
                    ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="4" v-if="item.suffix && model.fields[itemName].suffix">
                    <v-text-field
                        v-model="model.fields[itemName].suffix.value"
                        :label="trans(item.suffix.label)"
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="py-2" v-if="item.name && item.requisites">
            <v-row>
                <v-col
                    class="py-0"
                    cols="12"
                    v-if="typeof item.name.value != 'undefined' && model.fields[itemName].name"
                >
                    <v-text-field
                        v-model="model.fields[itemName].name.value"
                        :label="trans('fields.byOfferType.common.organizationName')"
                        dense
                    ></v-text-field>
                </v-col>

                <v-col
                    class="py-0"
                    cols="12"
                    v-if="typeof item.requisites.value != 'undefined' && model.fields[itemName].requisites"
                >
                    <v-text-field
                        v-model="model.fields[itemName].requisites.value"
                        :label="trans('fields.byOfferType.common.organizationRequisites')"
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <template v-if="item.from">
            <v-card-text class="py-2" v-if="item.from.prefix || typeof item.from.value != 'undefined' || item.from.suffix">
                {{ trans(item.from.label) }}
                <v-row>
                    <v-col class="py-0" cols="4" v-if="item.from.prefix && model.fields[itemName].from.prefix">
                        <v-text-field
                            v-model="model.fields[itemName].from.prefix.value"
                            :label="trans(item.from.prefix.label)"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col
                        class="py-0"
                        v-if="typeof item.from.value != 'undefined' && model.fields[itemName].from"
                        :cols="4 + (!item.from.suffix ? 4 : 0) + (!item.from.prefix ? 4 : 0)"
                    >
                        <v-text-field
                            v-model="model.fields[itemName].from.value"
                            :label="trans('fields.common.value')"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="4" v-if="item.from.suffix && model.fields[itemName].from.suffix">
                        <v-text-field
                            v-model="model.fields[itemName].from.suffix.value"
                            :label="trans(item.from.suffix.label)"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </template>
        <template v-if="item.to">
            <v-card-text class="py-2" v-if="item.to.prefix || typeof item.to.value != 'undefined' || item.to.suffix">
                {{ trans(item.to.label) }}
                <v-row>
                    <v-col class="py-0" cols="4" v-if="item.to.prefix && model.fields[itemName].to.prefix">
                        <v-text-field
                            v-model="model.fields[itemName].to.prefix.value"
                            :label="trans(item.to.prefix.label)"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col
                        class="py-0"
                        v-if="typeof item.to.value != 'undefined' && model.fields[itemName].to"
                        :cols="4 + (!item.to.prefix ? 4 : 0) + (!item.to.suffix ? 4 : 0)"
                    >
                        <v-text-field
                            v-model="model.fields[itemName].to.value"
                            :label="trans('fields.common.value')"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="4" v-if="item.to.suffix && model.fields[itemName].to.suffix">
                        <v-text-field
                            v-model="model.fields[itemName].to.suffix.value"
                            :label="trans(item.to.suffix.label)"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </template>

        <template v-if="item.period">
            <v-card-text class="py-2" v-if="typeof item.period.option != 'undefined'">
                <v-select
                    :items="periodOptions"
                    :label="trans('fields.common.period')"
                    v-model="model.fields[itemName].period.option"
                    dense
                ></v-select>
            </v-card-text>
        </template>
        <template v-if="hasOverrideText">
            <v-card-text class="py-2">
                <v-text-field
                    v-model="model.fields[itemName].text.value"
                    :label="trans('fields.common.overrideText')"
                    dense
                ></v-text-field>
            </v-card-text>
        </template>
        <v-card-text v-if="item.from || item.to || item.prefix || item.suffix">
            Пример поля:
            {{ example }}
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "OfferField",
        props: ['item', 'type', 'itemName', 'model', 'languageLocales'],
        data(){
            return {
                useOverrideText: false,
            }
        },
        computed: {
            hasOverrideText(){
                return !!this.item.text && typeof this.item.text.value != 'undefined' && !!this.model.fields[this.itemName].text
            },
            fieldTransPath(){
                return 'fields.byOfferType.'+this.type+'.'
            },
            fieldTransPathCommon(){
                return 'fields.common.';
            },
            example() {
                let t = (str) => this.trans(str, null, this.languageLocales[this.model.language_id]);
                let i = this.item;
                let m = this.model.fields[this.itemName];
                let ex = t(i.label) + ': ';
                if (m.from){
                    if (m.from.value !== '' && m.from.value !== null){
                        ex += ' ' + t(i.from.label) + ' ';
                        if (m.from.prefix){
                            if (m.from.prefix.value){
                                ex += m.from.prefix.value
                            }
                        }
                        ex += m.from.value;
                        if (m.from.suffix){
                            if (m.from.suffix.value){
                                ex += m.from.suffix.value
                            }
                        }
                    }
                }
                if (m.to){
                    if (m.to.value !== '' && m.to.value !== null){
                        ex += ' ' + t(i.to.label) + ' ';
                        if (m.to.prefix){
                            if (m.to.prefix.value){
                                ex += m.to.prefix.value
                            }
                        }
                        ex += m.to.value;
                        if (m.to.suffix){
                            if (m.to.suffix.value){
                                ex += m.to.suffix.value
                            }
                        }
                    }
                }
                if (m.period){
                    if (m.period.option){
                        ex += ' ' + t(i.period.options[m.period.option]) + ' ';
                    }
                }
                if (m.text){
                    if(m.text.value){
                        ex = m.text.value; // text override
                    }
                }
                return ex;
            },
            periodOptions() {
                let options = [];
                if (this.item.period){
                    options.push({
                        text: this.trans('period.hidden'),
                        value: null,
                    })
                    _.each(this.item.period.options, (text, value) => {
                        options.push({
                            text: this.trans(text),
                            value: value,
                        });
                    })
                }
                return options;
            }
        }
    }
</script>

<style scoped>

</style>
